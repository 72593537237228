import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'
import {
  FETCH_CLIENT_USER_BET_HISTORY,
  FETCH_CLIENT_USER_BET_HISTORY_DETAIL,
  FETCH_GAME_SET_BASIC,
  FETCH_GAME_CASINO_TRANSACTION,
} from '@/store/result/action'
import {
  UPDATE_CLIENT_USER_BET_CANCEL,
  DELETE_CLIENT_USER_BET_ALL,
  DELETE_CLIENT_USER_BET_ITEM,
} from '@/store/result/mutation'

const resultStore = {
  namespaced: true,
  state: {
    fetchClientUserBetHistory: [],
    fetchClientUserBetHistoryDetail: [],
    updateClientUserBetCancel: [],
    clientFetchGameSetBasic: [],
    fetchGameCasinoTransaction: [],
    deleteClientUserBetAll: [],
    deleteClientUserBetItem: [],
  },
  getters: {
    fetchClientUserBetHistory: state => state.fetchClientUserBetHistory,
    fetchClientUserBetHistoryDetail: state => state.fetchClientUserBetHistoryDetail,
    updateClientUserBetCancel: state => state.updateClientUserBetCancel,
    clientFetchGameSetBasic: state => state.clientFetchGameSetBasic,
    fetchGameCasinoTransaction: state => state.fetchGameCasinoTransaction,
    deleteClientUserBetAll: state => state.deleteClientUserBetAll,
    deleteClientUserBetItem: state => state.deleteClientUserBetItem,
  },
  mutations: {
    // updateField,
    [FETCH_CLIENT_USER_BET_HISTORY](state, fetchClientUserBetHistory) {
      state.fetchClientUserBetHistory = fetchClientUserBetHistory
    },
    [FETCH_CLIENT_USER_BET_HISTORY_DETAIL](state, fetchClientUserBetHistoryDetail) {
      state.fetchClientUserBetHistoryDetail = fetchClientUserBetHistoryDetail
    },
    [UPDATE_CLIENT_USER_BET_CANCEL](state, updateGameUserCancel) {
      state.updateGameUserCancel = updateGameUserCancel
    },
    [FETCH_GAME_SET_BASIC](state, clientFetchGameSetBasic) {
      state.clientFetchGameSetBasic = clientFetchGameSetBasic
    },
    [FETCH_GAME_CASINO_TRANSACTION](state, fetchGameCasinoTransaction) {
      state.fetchGameCasinoTransaction = fetchGameCasinoTransaction
    },
    [DELETE_CLIENT_USER_BET_ALL](state, deleteClientUserBetAll) {
      state.deleteClientUserBetAll = deleteClientUserBetAll
    },
    [DELETE_CLIENT_USER_BET_ITEM](state, deleteClientUserBetItem) {
      state.deleteClientUserBetItem = deleteClientUserBetItem
    },
  },
  actions: {
    async [FETCH_CLIENT_USER_BET_HISTORY]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
            query fetchClientUserBetHistory($site: String, $userid: String, $confirmed: String, $periodFrom: String, $periodTo: String) {
                fetchClientUserBetHistory(site: $site, userid: $userid, confirmed: $confirmed, periodFrom: $periodFrom, periodTo: $periodTo) {
                    idx
                    site
                    sports
                    category
                    title
                    groupId
                    gameDate
                    betDate
                    betCash
                    betResult
                    fold
                    totalOdds
                    totalHitCash
                    requestId
                    requestNickname
                    requestIp
                    confirmed
                    confirmId
                    confirmAt
                    confirmIp
                    isUserDeleted
                    uid
                    createdAt
                    updatedAt
                }
            }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
          confirmed: payload.confirmed,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_CLIENT_USER_BET_HISTORY, data.fetchClientUserBetHistory)
      console.log('FETCH_CLIENT_USER_BET_HISTORY : ', data.fetchClientUserBetHistory)
    },

    async [FETCH_CLIENT_USER_BET_HISTORY_DETAIL]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                  query fetchClientUserBetHistoryDetail($site: String, $userid: String, $groupId: String) {
                      fetchClientUserBetHistoryDetail(site: $site, userid: $userid, groupId: $groupId) {
                          idx
                          site
                          userid
                          nickname
                          category
                          sports
                          gameId
                          gameDate
                          leagueName
                          homeName
                          awayName
                          oddsType
                          oddsId
                          oddsHome
                          oddsDraw
                          oddsAway
                          odds
                          handicap
                          homeAway
                          groupId
                          seq
                          betCash
                          homeTeamResult
                          awayTeamResult
                          status
                          betResult
                          hitCash
                          totalBetResult
                          totalOdds
                          totalHitCash
                          confirmed
                          createdAt
                          updatedAt
                      }
                  }
              `,
        variables: {
          site: payload.site,
          userid: payload.userid,
          groupId: payload.groupId,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_CLIENT_USER_BET_HISTORY_DETAIL, data.fetchClientUserBetHistoryDetail)
      console.log('FETCH_CLIENT_USER_BET_HISTORY_DETAIL : ', data.fetchClientUserBetHistoryDetail)
    },

    // 배팅 취소 처리
    async [UPDATE_CLIENT_USER_BET_CANCEL]({ commit, state }, payload) {
      console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
                  mutation updateGameUserCancel($requestAlarmsBetInputArgs: RequestAlarmsBetInputArgs!, $ip: String!, $userid: String!){
                      updateGameUserCancel(requestAlarmsBetInputArgs: $requestAlarmsBetInputArgs, ip: $ip, userid: $userid) {
                          groupId
                      }
                  }
              `,
        variables: {
          requestAlarmsBetInputArgs: {
            groupId: payload.groupId,
            // 여기에 필요한 다른 정보들도 추가할 수 있습니다.
          },
          userid: payload.userid,
          ip: payload.ip,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_CLIENT_USER_BET_CANCEL, data.updateClientUserBetCancel)
      console.log('UPDATE_CLIENT_USER_BET_CANCEL : ', data.updateClientUserBetCancel)
    },
    async [FETCH_GAME_SET_BASIC]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
                  query fetchGameSetBasic($site: String) {
                      fetchGameSetBasic(site: $site) {
                          idx
                          site
                          userBetCancelTime
                          gameBetCancelTime
                          userBetHighAlarm
                          createdAt
                          updatedAt
                      }
                  }
              `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SET_BASIC, data.fetchGameSetBasic)
      console.log('FETCH_GAME_SET_BASIC : ', data.fetchGameSetBasic)
    },
    async [FETCH_GAME_CASINO_TRANSACTION]({ commit }, payload) {
      // console.log('payload:::', payload)
      const { data } = await apolloClient.query({
        query: gql`
            query fetchGameCasinoTransaction($site: String, $username: String, $periodFrom: String, $periodTo: String, $type: String, $searchOption: String, $inputValue: String) {
                  fetchGameCasinoTransaction(site: $site, username: $username, periodFrom: $periodFrom, periodTo: $periodTo, type: $type, searchOption: $searchOption, inputValue: $inputValue) {
                      idx
                      site
                      id
                      type
                      username
                      amount
                      refererId
                      processedAt
                      targetId
                      targetUsername
                      targetBalance
                      detailId
                      round
                      title
                      detailType
                      vendor
                      createdAt
                      updatedAt
                  }
              }
          `,
        variables: {
          site: payload.site,
          username: payload.username,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
          type: payload.type,
          searchOption: payload.searchOption,
          inputValue: payload.inputValue,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_CASINO_TRANSACTION, data.fetchGameCasinoTransaction)
      console.log('FETCH_GAME_CASINO_TRANSACTION : ', data.fetchGameCasinoTransaction)
    },

    // 유저 배팅내역 삭제
    async [DELETE_CLIENT_USER_BET_ALL]({ commit, state }, payload) {
      // console.log('update payload : ', state.fetchClientUserBetHistory)
      // const groupIds = state.fetchClientUserBetHistory.map(arg => arg.groupId)
      const formattedPayload = state.fetchClientUserBetHistory.map(item => ({
        groupId: item.groupId,
      }))
      console.log(formattedPayload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
              mutation deleteClientUserBetAll($requestAlarmsBetInputArgs: [RequestAlarmsBetInputArgs!]!){
                  deleteClientUserBetAll(requestAlarmsBetInputArgs: $requestAlarmsBetInputArgs)
              }
          `,
        variables: {
          requestAlarmsBetInputArgs: formattedPayload,
        },
        fetchPolicy: 'no-cache',
      })
      commit(DELETE_CLIENT_USER_BET_ALL, data.deleteClientUserBetAll)
      console.log('DELETE_CLIENT_USER_BET_ALL : ', data.deleteClientUserBetAll)
    },

    // 유저 배팅내역 삭제
    async [DELETE_CLIENT_USER_BET_ITEM]({ commit, state }, payload) {
      console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
              mutation deleteClientUserBetItem($requestAlarmsBetInputArgs: RequestAlarmsBetInputArgs!){
                  deleteClientUserBetItem(requestAlarmsBetInputArgs: $requestAlarmsBetInputArgs)
              }
          `,
        variables: {
          requestAlarmsBetInputArgs: {
            groupId: payload.groupId,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(DELETE_CLIENT_USER_BET_ITEM, data.deleteClientUserBetItem)
      console.log('DELETE_CLIENT_USER_BET_ITEM : ', data.deleteClientUserBetItem)
    },
  },
}

export default resultStore
